






import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Plan extends Vue {
  public loading: boolean = false;

  public mounted() {
    setTimeout(() => {
      this.$router.push('/serviceTasks');
    }, 3000);
  }
}
